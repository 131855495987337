import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_LANGUAGE, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, STORAGE, TIME_FORMAT_DEFAULT } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import AlarmSettingsService from 'api/alarmSettings/AlarmSettingsService';
import { AlarmNotificationTriggerDto, AlarmSettingDto, AlarmSettingVehicleDto, AlarmSettingsDaysOfWeekDto, AlarmTypeDataTypeEnum, AlarmTypeDto, AlarmTypeFieldDto } from 'api/alarmSettings/models/AlarmSettingDto';
import { AlarmType } from 'api/alarmSettings/models/AlarmType';
import UsersService from 'api/users/UsersService';
import DateTimePicker from 'common/components/dateTimePicker/DateTimePicker';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Container, Form, InputGroup, Row, Tab, Tabs } from 'react-bootstrap';
import styles from './AlarmSettingScreen.module.scss';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Reducers } from 'store/types';
import { Circle, MapContainer, TileLayer } from 'react-leaflet';
import DrawTools, { LocationArea } from 'common/components/maps/DrawTools';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import VehiclesService from 'api/vehicles/VehiclesService';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import DateFormat from 'common/components/dateFormat/dateFormat';
import TriggerAddress from './TriggerAddress';
import ZoomLevelControl from 'common/components/maps/ZoomLevelControl';
import moment from 'moment';
import Storage from '../../common/services/Storage';
import { AlarmSettingSearchCriteria } from 'api/alarmSettings/models/AlarmTriggersSearchCriteria';
import { Paged } from 'api/common/types/Page';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';

type Props = {
};

const AlarmSettingScreen: React.FC<Props> = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const [startHour, setStartHour] = useState<Date | null>(null);
    const [stopHour, setStopHour] = useState<Date | null>(null);
    const [area, setArea] = useState<LocationArea>();
    const [zoomDefault, setZoomDefault] = useState<number>(12);
    const [vehicles, setVehicles] = useState<SelectValueLabel[]>([]);
    const [daysOfWeek, setDaysOfWeek] = useState<number[]>([]);
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlarmSettingsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['ALARM_SETTINGS_WRITE']);
    const [refresh, setRefresh] = useState<number>(1);
    const [refreshDrops, setRefreshDrops] = useState<number>(1);
    const [refreshVehicles, setRefreshVehicles] = useState<number>(1);
    const [refreshDays, setRefreshDays] = useState<number>(1);
    const [position, setPosition] = useState<GeolocationPosition>();
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<AlarmSettingDto | null>(null);
    const [defaultWeekdays, setDefaultWeekdays] = useState<string[]>([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [item, setItem] = useState<AlarmSettingDto>();
    const initialStatus: AlarmSettingDto = {
        id: '',
        type: AlarmType.SPEED_LIMIT,
        name: '',
        startHour: undefined,
        stopHour: undefined,
        limit: 0,
        latitude: undefined,
        longitude: undefined,
        radius: undefined,
        zoom: zoomDefault,
        daysOfWeek: [],
        rowVersion: ''
    };
    const [formData, setFormData] = useState(initialStatus);
    const [alarmTypes, setAlarmTypes] = useState<AlarmTypeDto[]>([]);
    const [selectedAlarmType, setSelectedAlarmType] = useState<AlarmTypeDto>();
    const [alarmNotificationsPage, setAlarmNotificationsPage] = useState<Paged<AlarmNotificationTriggerDto>>();
    const [criteria, setCriteria] = useState<AlarmSettingSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'desc',
        orderColumn: 'an.date'
    });

    const weekdaysChecked = (i: number) => {
        if (daysOfWeek) {
            if (daysOfWeek.includes(i)) {
                return true;
            }
        }
        return false;
    };

    const changeWeekdaysChecked = (i: number) => {
        if (daysOfWeek.includes(i)) {
            setDaysOfWeek(daysOfWeek.filter(x => x !== i));
        } else {
            setDaysOfWeek([...daysOfWeek, i]);
        }
    };

    const getData = async () => {
        try {
            const _alarmTypes = await AlarmSettingsService.getAlarmTypes();
            setAlarmTypes(_alarmTypes);

            let result: any = initialStatus;
            const vehicles = await VehiclesService.getMyVehicles();

            if (id) {
                Loading.show();
                result = await AlarmSettingsService.getById(id);
                if (vehicles && result && result.vehicles) {
                    vehicles.forEach(v => {
                        result.vehicles.forEach((mv: AlarmSettingVehicleDto) => {
                            if (v.value === mv.vehicleId) {
                                v.selected = true;
                            }
                        });
                    });
                }
                if (result?.daysOfWeek) {
                    setDaysOfWeek(result?.daysOfWeek.map((x: { dayOfWeek: any; }) => x.dayOfWeek));
                }
                setSelectedAlarmType(_alarmTypes.find(x => x.name === result.type));
                setCriteria({ ...criteria, page: 1 });
                Loading.hide();
            } else {
                setSelectedAlarmType(_alarmTypes[0]);
            }

            setItem(result);
            setFormData(result);
            setStartHour(result.startHour);
            setStopHour(result.stopHour);
            setZoomDefault(result.zoom ?? zoomDefault);
            if (result && result.radius && result.latitude && result.longitude) {
                const area: LocationArea = { latitude: result.latitude, longitude: result.longitude, mRadius: result.radius, radius: result.radius };
                setArea(area);
            }
            setVehicles(vehicles);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get alarm setting information', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            setRefresh(refresh + 1);
            setRefreshDrops(refresh + 1);
        }
    };

    const getNotifications = async () => {
        try {
            Loading.show();
            const alertsList = await AlarmSettingsService.getAlarmTriggersList(criteria);
            console.log(alertsList);
            setAlarmNotificationsPage(alertsList);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get alarm setting information', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const setCurrentPosition = (position: GeolocationPosition) => {
        try {
            setPosition(position);
            setRefresh(refresh + 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get device model list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    // const clearArea = () => {
    //     setArea(undefined);
    //     setFormData({ ...formData, latitude: undefined, longitude: undefined, radius: undefined });
    //     setRefresh(refresh + 1);
    // }
    const clearArea = () => {
        setArea(undefined);
        setFormData({ ...formData, latitude: undefined, longitude: undefined, radius: undefined });
        setRefresh(refresh + 1);
    }

    useEffect(() => {
        if (type === 'new') {
            Loading.show();
            navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => setCurrentPosition(position), () => Loading.hide());
        }
    }, [type]);

    useEffect(() => {
        setRefreshDays(refreshDays + 1);
    }, [daysOfWeek]);

    useEffect(() => {
        setDefaultWeekdays(Array.apply(null, Array(7)).map(function (_, i) {
            moment.locale(Storage.get(STORAGE.CURRENT_LOCALE) ?? DEFAULT_LANGUAGE,{ week: { dow: 0 } });
            return moment().startOf('week').weekday(i).format('ddd');
        }));
    }, [userProfile?.languageCode]);

    useEffect(() => {
        getData().catch(console.error);
    }, [id, type]);

    useEffect(() => {
        getNotifications().catch(console.error);
    }, [criteria]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            navigate(`/alarmsetting/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            navigate('/alarmsettings');
        }
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }
        try {
            await AlarmSettingsService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            toast.success(t('messages.record_delete_success'));
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete alarm settings', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    const showRemoveItemDialog = async (item: AlarmSettingDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: AlarmSettingDto = {
                id: formData.id,
                name: formData.name,
                type: formData.type,
                startHour: formData.startHour ?? undefined,
                stopHour: formData.stopHour ?? undefined,
                limit: formData.limit,
                latitude: formData.latitude ?? undefined,
                longitude: formData.longitude ?? undefined,
                radius: formData.radius ?? undefined,
                rowVersion: formData.rowVersion,
                zoom: zoomDefault,
                enabled: formData.enabled
            };
            model.vehicles = [];
            model.daysOfWeek = [];
            if (vehicles) {
                vehicles.forEach(x => {
                    if (x.selected) {
                        model.vehicles?.push({ vehicleId: x.value || '' });
                    }
                })
            }
            model.daysOfWeek = daysOfWeek.map(x => { return { dayOfWeek: x } });
            if (model && model.id) {
                await AlarmSettingsService.update(model)
                navigateTo('details', id);
            } else if (model) {
                const id = await AlarmSettingsService.create(model)
                navigateTo('details', id);
            }
            Loading.hide();
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update alarm setting with id: ${form.id}`, error);
            toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    // useEffect(() => {
    //     if (area) {
    //         setFormData({ ...formData, latitude: area.latitude, longitude: area.longitude, radius: area.mRadius });
    //     } else {
    //         setFormData({ ...formData, latitude: undefined, longitude: undefined, radius: undefined });
    //     }
    // }, [area]);
    useEffect(() => {
        if (area) {
            setFormData({ ...formData, latitude: area.latitude, longitude: area.longitude, radius: area.mRadius });
        } else {
            setFormData({ ...formData, latitude: undefined, longitude: undefined, radius: undefined });
        }
    }, [area]);

    const onCreateArea = (area: LocationArea) => {
        setArea(area);
    }

    const renderMap = () => {
        return <>
            <MapContainer center={[area ? area.latitude : position?.coords?.latitude ?? 52.516316, area ? area.longitude : position?.coords?.longitude ?? 13.377825]} maxZoom={18} minZoom={6} zoom={zoomDefault} scrollWheelZoom={true} className={styles.map}>
            {zoomDefault && <ZoomLevelControl onZoomChange={setZoomDefault} level={zoomDefault} />}
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {area ?
                    <Circle center={[area.latitude, area.longitude]} radius={area.mRadius}></Circle> :
                    (isDetails ? <></> : <DrawTools onCreated={onCreateArea} />)
                }
            </MapContainer></>
    }

    const check = (item: SelectValueLabel) => {
        if (vehicles) {
            vehicles.forEach(x => {
                if (x.value === item.value) {
                    x.selected = !(x.selected ?? false)
                }
            });
            setVehicles(vehicles);
            setRefreshVehicles(refreshVehicles + 1);
        }
    }

    const renderVehicles = () => {
        return <>{vehicles && <> {vehicles.map(x => <InputGroup key={x.value} className="mb-3">
            <InputGroup.Checkbox onChange={_ => check(x)} checked={x.selected ?? false} disabled={isDetails} />
            <Form.Control type="text" defaultValue={x.label} readOnly={true} />
        </InputGroup>
        )}</>}
        </>
    }

    const renderAlerts = () => {
        return <Container fluid>
           <ListingTable
                columns={alertsTableColumns}
                rows={alarmNotificationsPage?.items || []}
                allowHover={true}
                initialSearch={{ colField: 'an.date', isOrderAsc: false }}
            />
            <PaginationWithInfo
                    itemName={t('alarm_settings.notifications.title')}
                    currentPage={alarmNotificationsPage?.currentPage ?? 1}
                    pageItems={alarmNotificationsPage?.items.length || 0}
                    totalItems={alarmNotificationsPage?.totalItems || 0}
                    onChange={page => setCriteria({ ...criteria, page })}
                />
        </Container>
    }

    const alertsTableColumns: ListingTableColumn<AlarmNotificationTriggerDto>[] = [
        {
            name: t('alarm_settings.notifications.vehicle'),
            renderCell: row => <div>{row.licensePlate} - {row.designation}</div>,
        },
        {
            name: t('alarm_settings.notifications.date'),
            renderCell: row => <div>{row.notificationDate && <DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={row.notificationDate} />}</div>,
        },
        {
            name: t('alarm_settings.notifications.address'),
            renderCell: row => <TriggerAddress latitude={row.latitude} longitude={row.longitude} triggerDate={row.triggerDate} />,
        }
    ];

    const onChangeCoordinates = () => {
        if (formData.latitude && formData.longitude) {
            const p: GeolocationPosition = {
                coords: {
                    latitude: formData.latitude,
                    longitude: formData.longitude,
                    accuracy: 0,
                    altitude: null,
                    altitudeAccuracy: null,
                    heading: null,
                    speed: null
                },
                timestamp: Date.now()
            };
            setCurrentPosition(p);
            setZoomDefault(zoomDefault);
            if (formData.radius) {
                setArea({ latitude: formData.latitude, longitude: formData.longitude, mRadius: formData.radius, radius: formData.radius });
            }
        }
    }

    const renderFormField = (f: AlarmTypeFieldDto) => {
        switch (f.name) {
            case 'name': return (
                <Col xs={12} key={f.name}>
                    <Form.Group className="mb-3" controlId={f.name}>
                        <Form.Label>{t('alarm_settings.name')}{!isDetails && f.required ? '*' : ''}</Form.Label>
                        <Form.Control required={f.required} name={f.name} type="text" maxLength={250} defaultValue={item?.name} readOnly={isDetails} onChange={handleChange} placeholder={t('alarm_settings.name')} />
                    </Form.Group>
                </Col>
            )
            // case 'enabled': return (
            //     <Col xs={12} key={f.name}>
            //         <Form.Group className="mb-3" controlId={f.name}>
            //             <Form.Label>{t('alarm_settings.enabled')}{!isDetails && f.required ? '*' : ''}</Form.Label>
            //             <Form.Check name={f.name} type="switch" defaultChecked={item?.enabled} onChange={handleChange} required={f.required} readOnly={isDetails} />
            //         </Form.Group>
            //     </Col>
            // )
            case 'start_hour': return (<>
                <Row>
                <Form.Label>{t('alarm_settings.day_of_week')}{!isDetails && f.required ? '*' : ''}</Form.Label>
                { defaultWeekdays.map((day, index) => (
                <Col xs={12} sm={3} xl={1} key={`${refreshDays}-day--${day}`} className="mb-3">
                    <Form.Check label={day} type='switch' defaultChecked={weekdaysChecked(index)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        changeWeekdaysChecked(index);
                        }} disabled={isDetails} />
                </Col>))}
                </Row>
                <Col xs={12} md={6} key={f.name}>
                    <Form.Group className="mb-3" controlId={f.name}>
                        <Form.Label>{t('alarm_settings.start_hour')}{!isDetails && f.required ? '*' : ''}</Form.Label>
                        <DateTimePicker required={f.required} timeIntervals={15} selected={startHour} showTimeSelect showTimeSelectOnly timeCaption="Time" readOnly={isDetails} dateFormat={TIME_FORMAT_DEFAULT} customInput={<Form.Control name="startHour" type='text' />} onChange={(date) => { setStartHour(date); handleChange({ target: { name: 'startHour', value: date } }); }} />
                    </Form.Group>
                </Col></>
            )
            case 'stop_hour': return (
                <Col xs={12} md={6} key={f.name}>
                    <Form.Group className="mb-3" controlId={f.name}>
                        <Form.Label>{t('alarm_settings.stop_hour')}{!isDetails && f.required ? '*' : ''}</Form.Label>
                        <DateTimePicker required={f.required} timeIntervals={15} selected={stopHour} showTimeSelect showTimeSelectOnly timeCaption="Time" readOnly={isDetails} dateFormat={TIME_FORMAT_DEFAULT} customInput={<Form.Control name="stopHour" type='text' />} onChange={(date) => { setStopHour(date); handleChange({ target: { name: 'stopHour', value: date } }); }} />
                    </Form.Group>
                </Col>
            )
            case 'limit': return (
                <Col xs={12} md={12} key={f.name}>
                    <Form.Group className="mb-3" controlId={f.name}>
                        <Form.Label>{t('alarm_settings.limit')}{!isDetails && f.required ? '*' : ''}</Form.Label>
                        <Form.Control required={f.required} name={f.name} defaultValue={item?.limit} type="number" readOnly={isDetails} onChange={handleChange} placeholder={t('alarm_settings.limit')} />
                    </Form.Group>
                </Col>
            )
            case 'latitude': case 'longitude': return (
                <Col xs={12} md={4} key={f.name}>
                    <Form.Group className="mb-3" controlId={f.name}>
                        <Form.Label>{t('alarm_settings.' + f.name)}{!isDetails && f.required ? '*' : ''}</Form.Label>
                        <Form.Control required={f.required} name={f.name}
                            value={formData[f.name] || ''} type="text" readOnly={isDetails}
                            onChange={handleChange}
                            onBlur={onChangeCoordinates} placeholder={t('alarm_settings.' + f.name)} />
                    </Form.Group>
                </Col>
            )
            case 'radius': return (<>
                <Col xs={12} md={2} key={f.name}>
                    <Form.Group className="mb-3" controlId={f.name}>
                        <Form.Label>{t('alarm_settings.' + f.name)}(m){!isDetails && f.required ? '*' : ''}</Form.Label>
                        <Form.Control required={f.required} name={f.name} value={formData.radius || ''} type="number" step={0.01} readOnly={isDetails} onChange={handleChange} onBlur={onChangeCoordinates} placeholder={t('alarm_settings.' + f.name)} />
                    </Form.Group>
                </Col>
                {!isDetails && <Col xs={12} md={2} key={'btn_' + f.name}>
                    <div className={styles.smallButtonsContainer}>
                        <Button variant="outline-danger" size="sm" type="button" className={styles.button} onClick={clearArea}>
                            {t('common.clear')}
                        </Button>
                    </div>
                </Col>}
                <Row key={`map_${refresh}`}>
                    {renderMap()}
                </Row>
            </>)
            default:
                break;
        }
    }

    return (
        <ScreenTitle title={t('alarm_settings.title')}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => { navigateTo() }}>
                    {t('alarm_settings.title')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t(`common.${type}`)}</Breadcrumb.Item>
            </Breadcrumb>
            <Form onSubmit={onSubmit} noValidate validated={validated}>
                <Tabs defaultActiveKey="main" className="mb-3" fill>
                    <Tab eventKey="main" title={t('alarm_settings.alarm')}>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>{t('alarm_settings.type')} *</Form.Label>
                                    <Form.Select name="type" disabled={isDetails} key={`type_${refreshDrops}`} defaultValue={item?.type}
                                        onChange={(e: any) => {
                                            handleChange(e);
                                            setSelectedAlarmType(alarmTypes.find(x => x.name === e.target.value));
                                        }}>
                                        {
                                            alarmTypes.map(l => <option key={l.name} value={l.name}>{t(`alarm_settings.types.${l.name}`)}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {selectedAlarmType?.fields.map(renderFormField)}

                            <Col xs={12} key={'enabled'}>
                                <Form.Group className="mb-3" controlId={'enabled'}>
                                    <Form.Label>{t('alarm_settings.enabled')}</Form.Label>
                                    <Form.Check name={'enabled'} type="switch" checked={formData.enabled}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormData({ ...formData, enabled: e.target.checked });
                                        }}
                                        disabled={isDetails} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="vehicles" title={t('alarm_settings.vehicles')} key={refreshVehicles}>
                        {renderVehicles()}
                    </Tab>
                    <Tab eventKey="alerts" title={t('alarm_settings.notifications.title')}>
                        <Row>
                            {renderAlerts()}
                        </Row>
                    </Tab>
                </Tabs>
                <div className={styles.buttonsContainer}>
                    <Button variant="secondary" type="button" className={styles.button} onClick={() => navigateTo()}>
                        {t('common.cancel')}
                    </Button>
                    {isDetails && hasAlarmSettingsWritePolicy &&
                        <Button variant="danger" type="button" className={styles.button} onClick={() => showRemoveItemDialog(item as AlarmSettingDto)} >
                            {t('common.delete')}
                        </Button>
                    }
                    {isDetails && hasAlarmSettingsWritePolicy &&
                        <Button variant="warning" type="button" className={styles.button} onClick={() => { navigateTo('edit', item?.id); }}>
                            {t('common.edit')}
                        </Button>
                    }
                    {!isDetails && hasAlarmSettingsWritePolicy &&
                        <Button variant="primary" type="submit" className={styles.button}>
                            {t('common.save')}
                        </Button>
                    }
                </div>
            </Form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: item?.name ?? '' })} />
        </ScreenTitle>
    );
};

export default AlarmSettingScreen;
